import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import * as socialMediaRequestFormStyles from "./social-media-promotion-request.module.scss"

const SocialMediaPromotionRequestForm = ({ pageContext, location }) => {
  const pageTitle = "Social Media Promotion Request Form"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <div className={socialMediaRequestFormStyles.contentWrapper}>
          <h2>Social Media Promotion Request</h2>

          <form
            name="social-media-promotion-request"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            enctype="multipart/form-data"
          >
            <small>
              <span
                className="text-danger"
                style={{ fontSize: `1.1rem`, fontWeight: `bold` }}
              >
                *
              </span>{" "}
              indicates required field
            </small>
            <input
              type="hidden"
              name="form-name"
              value="social-media-promotion-request"
            />
            <div className="form-group">
              <label htmlFor="rocky-brands-account-number">
                Rocky Brands Account Number{" "}
                <span style={{ color: `red` }}>*</span>
                <input
                  type="number"
                  id="rocky-brands-account-number"
                  name="rocky-brands-account-number"
                  required
                  maxLength={6}
                />
              </label>
              <small>First Six Digits Only</small>
            </div>
            <div className="form-group">
              <label htmlFor="store-name">
                Store Name <span style={{ color: `red` }}>*</span>
                <input type="text" id="store-name" required />
              </label>
            </div>
            {/* address */}
            <div className="form-group">
              <label htmlFor="social-media-request-address-line-1">
                Store Address <span style={{ color: `red` }}>*</span>
              </label>
              <input
                type="text"
                id="social-media-request-address-line-1"
                name="social-media-request-address-line-1"
                required
              />
              <small>Address line 1</small>
            </div>
            <div className="form-group">
              <input
                type="text"
                id="social-media-request-address-line-2"
                name="social-media-request-address-line-2"
              />
              <label
                style={{ fontWeight: `normal` }}
                htmlFor="social-media-request-address-line-2"
              >
                <small>Address line 2</small>
              </label>
            </div>
            {/* city */}
            <div className="form-group">
              <label htmlFor="social-media-request-address-city">
                City <span style={{ color: `red` }}>*</span>
              </label>
              <input
                type="text"
                id="social-media-request-address-city"
                name="social-media-request-address-city"
                required
              />
            </div>
            {/* state */}
            <div className="form-group">
              <label htmlFor="state">
                State <span style={{ color: `red` }}>*</span>
              </label>
              <select name="state" id="state">
                <option value="" selected="selected">
                  State
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            {/* ZIP CODE */}
            <div className="form-group">
              <label htmlFor="social-media-request-rocky-brands-zip-code">
                Zip Code <span style={{ color: `red` }}>*</span>
              </label>
              <input
                type="text"
                id="social-media-request-rocky-brands-zip-code"
                pattern="[0-9]{5}"
                required
              />
            </div>
            {/* Country */}
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <select id="country" name="country">
                <option>Country</option>
                <option value="AF">Afghanistan</option>
                <option value="AX">Aland Islands</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo</option>
                <option value="CD">
                  Congo, Democratic Republic of the Congo
                </option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="CI">Cote D'Ivoire</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CW">Curacao</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands (Malvinas)</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GG">Guernsey</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HM">Heard Island and Mcdonald Islands</option>
                <option value="VA">Holy See (Vatican City State)</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran, Islamic Republic of</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IM">Isle of Man</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KP">
                  Korea, Democratic People's Republic of
                </option>
                <option value="KR">Korea, Republic of</option>
                <option value="XK">Kosovo</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Lao People's Democratic Republic</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macao</option>
                <option value="MK">
                  Macedonia, the Former Yugoslav Republic of
                </option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia, Federated States of</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PS">Palestinian Territory, Occupied</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Reunion</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="BL">Saint Barthelemy</option>
                <option value="SH">Saint Helena</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="MF">Saint Martin</option>
                <option value="PM">Saint Pierre and Miquelon</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="CS">Serbia and Montenegro</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SX">Sint Maarten</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard and Jan Mayen</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan, Province of China</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks and Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VE">Venezuela</option>
                <option value="VN">Viet Nam</option>
                <option value="VG">Virgin Islands, British</option>
                <option value="VI">Virgin Islands, U.s.</option>
                <option value="WF">Wallis and Futuna</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </select>
            </div>
            <small
              style={{
                display: `block`,
                marginBottom: `3rem`,
              }}
            >
              If multiple locations are being used for this promotion, please
              enter your headquarters address
            </small>

            <fieldset
              name="who-are-you"
              className="form-group"
              style={{ marginBottom: `3rem` }}
            >
              <legend htmlFor="who-are-you">
                <strong>Who Are You?</strong>
              </legend>

              <div>
                <label className="radio-alt">
                  <input
                    type="radio"
                    name="who-are-you"
                    id="who-are-you-rep"
                    value="Rep"
                  />
                  <span>Rep</span>
                </label>
              </div>
              <div>
                <label className="radio-alt">
                  <input
                    type="radio"
                    name="who-are-you"
                    id="who-are-you-customer"
                    value="Customer"
                  />
                  <span>Customer</span>
                </label>
              </div>
            </fieldset>
            {/* email */}
            <div className="form-group">
              <label htmlFor="email-address">
                Your Email Address <span style={{ color: `red` }}>*</span>
              </label>
              <input
                type="text"
                id="email-address"
                name="email-address"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              />
              <small>i.e. email@domain.com</small>
            </div>
            <div className="form-group">
              <label htmlFor="contact-full-name">
                Who is the contact for your social media account?{" "}
                <span style={{ color: `red` }}>*</span>
              </label>
              <input
                type="text"
                id="contact-full-name"
                name="contact-full-name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="social-media-request-contact-email-address">
                Social Media Contact Email Address Or Phone Number{" "}
                <span style={{ color: `red` }}>*</span>
              </label>
              <input
                type="text"
                id="social-media-request-contact-email-address"
                name="social-media-request-contact-email-address"
                required
              />
            </div>
            <fieldset
              name="social-media-request-brands"
              className="form-group"
              style={{ marginTop: `2rem` }}
            >
              <legend htmlFor="social-media-request-featured-brands">
                Brands you are featuring:
              </legend>
              <div className="form-group">
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-featured-brands-rocky-boots"
                      name="social-media-request-featured-brands"
                      value="rocky-boots"
                    />
                    <span>Rocky Boots</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-featured-brands-georgia-boot"
                      name="social-media-request-featured-brands"
                      value="georgia-boot"
                    />
                    <span>Georgia Boot</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-featured-brands-durango-boots"
                      name="social-media-request-featured-brands"
                      value="durango-boots"
                    />
                    <span>Durango Boots</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-featured-brands-the-original-muck-boot-company"
                      name="social-media-request-featured-brands"
                      value="the-original-muck-boot-company"
                    />
                    <span>The Original Muck Boot Company</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-featured-brands-xtratuf"
                      name="social-media-request-featured-brands"
                      value="xtratuf"
                    />
                    <span>XTRATUF</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-featured-brands-ranger"
                      name="social-media-request-featured-brands"
                      value="ranger"
                    />
                    <span>Ranger</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-featured-brands-slipgrips"
                      name="social-media-request-featured-brands"
                      value="slipgrips"
                    />
                    <span>SlipGrips</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-featured-brands-lehigh-safety-shoes"
                      name="social-media-request-featured-brands"
                      value="lehigh-safety-shoes"
                    />
                    <span>Lehigh Safety Shoes</span>
                  </label>
                </div>
              </div>
            </fieldset>

            <div
              className="form-group"
              style={{
                display: `flex`,
                flexDirection: `column`,
                marginTop: `3rem`,
              }}
            >
              <label for="specific-styles" htmlFor="specific-styles">
                Specific styles you are featuring (SKUs Please):
              </label>
              <textarea
                id="specific-styles"
                name="specific-styles"
                style={{ marginTop: `1rem`, marginBottom: `1rem` }}
              />
            </div>

            <fieldset
              name="social-media-request-promotion-details"
              className="form-group"
              style={{ marginTop: `2rem` }}
            >
              <legend htmlFor="social-media-request-promotion-details">
                <strong> Promotion Details:</strong>
              </legend>
              <div className="form-group">
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-promotion-details-giveaway"
                      name="social-media-request-promotion-details"
                      value="giveaway"
                    />
                    <span>Giveaway</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-promotion-details-gwp"
                      name="social-media-request-promotion-details"
                      value="gwp"
                    />
                    <span>GWP</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-promotion-details-event"
                      name="social-media-request-promotion-details"
                      value="event"
                    />
                    <span>Event</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-promotion-details-sale"
                      name="social-media-request-promotion-details"
                      value="sale"
                    />
                    <span>Sale</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-promotion-details-general-promotion"
                      name="social-media-request-promotion-details"
                      value="general-promotion"
                    />
                    <span>General Promotion</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-promotion-details-new-styles"
                      name="social-media-request-promotion-details"
                      value="new-styles"
                    />
                    <span>New Styles</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="social-media-request-promotion-details-other"
                      name="social-media-request-promotion-details"
                      value="other"
                    />
                    <span>Other</span>
                  </label>
                </div>
              </div>
            </fieldset>
            <div className="form-group" style={{ marginTop: `2rem` }}>
              <label htmlFor="social-media-request-start-date-promotion">
                Date you want to start promoting event:
              </label>
              <input
                className="date-input"
                type="date"
                id="social-media-request-start-date-promotion"
                name="social-media-request-start-date-promotion"
              />
              <br />
            </div>
            <div className="form-group" style={{ marginTop: `1rem` }}>
              <label htmlFor="social-media-request-start-date">
                Start Date of the Event:
              </label>
              <input
                type="date"
                id="social-media-request-start-date"
                name="social-media-request-start-date"
              />
              <br />
            </div>

            <div className="form-group" style={{ marginTop: `1rem` }}>
              <label htmlFor="social-media-request-end-date">
                End Date of the Event:
              </label>
              <input
                type="date"
                id="social-media-request-end-date"
                name="social-media-request-end-date"
              />
              <br />
            </div>

            <div className="form-group" style={{ marginTop: `2rem` }}>
              <label htmlFor="social-media-request-facebook-page">
                Facebook page to Cross Promote With:{" "}
                <span style={{ color: `red` }}>*</span>
              </label>
              <br />
              <input
                type="text"
                id="social-media-request-facebook-page"
                name="social-media-request-facebook-page"
                required
                pattern=".*facebook\.com/.+"
              />
              <small>ex. https://www.facebook.com/lehighsafetyshoes</small>
            </div>

            <div className="form-group " style={{ marginTop: `2rem` }}>
              <label htmlFor="social-media-request-upload-picture">
                Upload any picture that you want used.
              </label>
              <br />
              <input
                className="file-upload"
                type="file"
                id="social-media-request-upload-picture"
                name="social-media-request-upload-picture"
              />
              <br />
              <small>
                Outside of store, in store picture with product display,
                customer logo, etc.
              </small>
            </div>

            {/* submit button */}
            <div>
              <input className="CTAblack" type="submit" value="Submit" />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default SocialMediaPromotionRequestForm
